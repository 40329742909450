import React from "react";
import NavigationBar from "../components/NavigationBar";
import Layout from "../components/Layout";
import HeaderCard from "../components/HeaderCard";
import ArticleCardBlog from "../components/ArticleCardArticles";
import Footer from "../components/Footer";
import {useStaticQuery, graphql} from 'gatsby';
import "../styles/global.css";
import NewsletterSignUp from "../components/NewsletterSignUp";
import {siteMetadata} from "../../gatsby-config";
import Seo from "../components/Seo";


const Articles = () => {
    const articles = useStaticQuery(graphql`
    query ChronologicalArticles {
      data: allMdx (sort: { fields: [frontmatter___date], order: DESC },
              limit: 10){
      nodes {
          frontmatter {
            title
            date
            tags
            category
        }
        slug
      }
    }
    }
    `)

    let articleCards = articles.data.nodes.map(function(node, i) {
        let rightCell = ((i+1) % 2 === 1);
        if (rightCell) {
            return <ArticleCardBlog article={node} key={i}></ArticleCardBlog>
        }
        else {
            return <ArticleCardBlog left article={node} key={i}></ArticleCardBlog>
        }
    })
    // for (let i = 0; i < articles.data.nodes.length; i++) {
    //     articleCards.push(
    //         <ArticleCardHome key={articles.data.nodes[i].frontmatter.title} article={articles.data.nodes[i]}></ArticleCardHome>
    //     );
    // }

    return (
        <Layout>
            <Seo title="FlatPack FinTech - Articles"/>
            <NavigationBar />
            <HeaderCard title="Articles" tagline="Stay ahead of the curve with the latest in FinTech." />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-0">
                {articleCards}
            </div>
            <NewsletterSignUp headline="By Builders, for Builders" tagline="Sign up to get first access to guides, tips and more." />
            <Footer />
        </Layout>
    )
}

export default Articles