import React from "react";
import "../styles/global.css";

function HeaderCard(props) {
    return ( <div className="w-full bg-secondary border-4 border-primary px-4 py-6 desktop:pl-14 desktop:pt-10 desktop:pb-8 space-y-6">
            <h1 className="text-7xl font-bold">{props.title}</h1>
            <p className="text-4xl font-semibold">{props.tagline}</p>
    </div>
    )
}

export default HeaderCard