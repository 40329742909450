import React from "react";
import {Link} from "gatsby";
import "../styles/global.css";


function ArticleCardHome(props) {
    let tagsArray = [];
    let tags = props.article.frontmatter.tags;
    for (let i = 0; i < tags.length; i++) {
        tagsArray.push(<div key={i} className="bg-primary rounded-full px-[0.75rem] py-[0.375rem] text-white text-sm font-semibold max-w-fit inline-block mr-2">
            {tags[i].toUpperCase()}
        </div>)
    }

    function renderCategoryTag(category) {
        let colour = '';
        if (category === "SaaS") {

        }
        let buttonClass = "rounded-full px-[0.75rem] py-[0.375rem] text-sm font-semibold max-w-fit inline-block mr-2";
    }

    let category = props.article.frontmatter.category;
    tagsArray.push(<div className="bg-primary rounded-full px-[0.75rem] py-[0.375rem] text-white text-sm font-semibold max-w-fit inline-block mr-2">
        {props.article.frontmatter.category}
    </div>)

    function getDateString(date) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let pattern = /(\d{2})-(\d{2})-(\d{4})/;
        let dateStr = date.toString();
        let processedDate = new Date(dateStr.replace(pattern, '$3-$2-$1'));
        return months[processedDate.getMonth()] + " " + processedDate.getFullYear()
    }

    const processedDate = getDateString(props.article.frontmatter.date);
    let hoverEffects = "hover:drop-shadow-2xl hover:shadow-inner-lg hover:-translate-y-[4px]";

    if (props.left) {
        const cardClasses = "flex-1 flex-col bg-secondary border-4 lg:border-l-0 border-t-0 border-primary px-6 py-10 " + hoverEffects;
        return (
            <Link to={`/${props.article.slug}`}>
                <div className={cardClasses}>
                    <div className="flex flex-col space-y-4 h-full justify-center">
                        <p>{processedDate}</p>
                        <h3 className="text-3xl font-semibold">{props.article.frontmatter.title}</h3>
                        <div>
                            {tagsArray}
                        </div>
                    </div>
                </div>
            </Link>)
        }
    else {
        const cardClasses = "flex-1 flex-col bg-secondary border-b-4 border-x-4 border-primary px-6 py-10 " + hoverEffects;
        return (
            <Link to={`/${props.article.slug}`}>
                <div className= {cardClasses}>
                    <div className="flex flex-col space-y-4 h-full justify-center">
                        <p>{processedDate}</p>
                        <h3 className="text-3xl font-semibold">{props.article.frontmatter.title}</h3>
                        <div>
                            {tagsArray}
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}

export default ArticleCardHome;